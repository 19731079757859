// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from './Hero';
import Meta from '../shared/Meta';
import CostEstimator from './CostEstimator';
import Triple from './Triple';
import VendorsToChooseFrom from './VendorsToChooseFrom';
import CtaImage from './CtaImage';
import LogoList from './LogoList';
import AnyEvent from './AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class TacoCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Taco Food ${cityConfig.vehicle_type} Catering - Weddings, Parties & Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`${cityConfig.city_name}'s best taco ${vehicleType}s are ready to serve your next big event! Book one or multiple ${vehicleType}s for your next party!`}
          image="https://static.seattlefoodtruck.com/photos/Taco_Header.jpeg"
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Taco Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/Taco_Header.jpeg">
          <h1>Taco Food {cityConfig.vehicle_type} Catering</h1>
          <p>{cityConfig.city_name}'s best taco {vehicleType}s are ready to serve your next big event! Book one or multiple {vehicleType}s for your next party by using our free catering request form below.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="PaddedSection">
          <div className="Container">
            <h3>How it works</h3>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering1.png"
                title="1. Tell us about your event"
                text={`Let us know the details of your upcoming party, and we'll reach out to our growing list of over 500 of ${cityConfig.city_name}'s best food ${vehicleType}s for availability and pricing.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering2.png"
                title="2. Receive quotes"
                text="We'll do the hard work for you and send you a selection of vendors that fit your specifications. You can chat directly with each vendor to learn more about their menu options and finalize details."
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering3.png"
                title={`3. Book your ${vehicleType}`}
                text={`Select your favorite food ${vehicleType} and book directly with the vendor. We never add commission or service fees, so you'll know you're always getting the best price.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Taco1.jpeg" imgClass="img-tacos1"/>
              <div className={styles.Cta_copy}>
                <h4>Taco {cityConfig.vehicle_type}s On Every Corner</h4>
                <p>
                  Looking to bring some spice to your next event? Then why not book a taco {vehicleType} to help feed your hungry guests! Give guests variety with delicious filling options, such as al pastor, a signature dish from <Link to="/food-trucks/solamente-al-pastor">Solamente Al Pastor</Link> food {vehicleType}, served with thin slices of pork and pineapple made to order from their custom-built trope! Or go classic with carne asada tacos from {cityConfig.city_name}'s favorite, <Link to="/food-trucks/taqueria-los-chilangos">Los Chilangos</Link> food {vehicleType}, topped with fresh cilantro and onion. Looking to keep your guests warm? How about a delicious and authentic bowl of tortilla soup from Plaza Garcia Express food {vehicleType}, made with crispy corn tortillas and shredded chicken with fresh avocado and cheese. From 50 to 500, our vendors can handle parties of any size, while serving something for everyone!
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Taco2.jpeg" imgClass="img-tacos2"/>
              <div className={styles.Cta_copy}>
                <h4>Let's taco bout it</h4>
                <p>
                  Tacos aren't just limited to Latin flavors! Fan-favorite <Link to="/food-trucks/marination-mobile">Marination Mobile</Link> food {vehicleType} combines their Hawaiian background with delicious tacos and sliders, all topped with their famous Nunya sauce! Award-winning food {vehicleType} <Link to="/food-trucks/off-the-rez">Off The Rez</Link> pulls from their Native American heritage by serving their take on tacos, serving them over their signature fry bread! If you are looking for a place to take your tastebuds on a ride, then head over to the <Link to="/food-trucks/bomba-fusion">Bomba Fusion</Link> food {vehicleType}, a Mexican Korean fusion {vehicleType} serving their take on Bibimbap tacos! Whatever your flavor, {cityConfig.city_name}'s best food {vehicleType}s can help cater your next event in style. Use our free catering request form below for pricing and availability!
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'tacos' }} />

        <CostEstimator title={`Taco ${cityConfig.vehicle_type} Catering Cost Estimator`}/>

        <section className="PaddedSection">
          <div className="Container">
            <LogoList title="Some of the companies we serve" />
          </div>
        </section>

        <AnyEvent/>

        <section className="PaddedSection">
          <div className="Container">
            <div className={styles.BookingCta}>
              <h3>Think of us for your next event</h3>
              <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
              <a
                className="Button"
                href="/catering/register"
              >
                Food {cityConfig.vehicle_type} Catering
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

TacoCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TacoCatering);
